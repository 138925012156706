import { createContext } from "react";

export const MusicInfoContext = createContext();

export const MusicInfoProvider = (props) => {
  const music = [
    {
      id: "1",
      image: "https://c.tenor.com/kuue1kTkjysAAAAC/rain-sailor.gif",
      imageCredit: "https://tenor.com/view/rain-sailor-moon-gif-20481725",
      title: "rain",
      youtube:
        "https://backend.cryosoft.co.ke/v1/storage/buckets/6239da3c924319cb6478/files/6239e54adab64c5308fb/view?project=6239da2ca5037081177d&mode=admin",
    },
    {
      id: "2",
      image:
        "https://media0.giphy.com/media/SWhsTrEYSrGd4CAhNC/giphy.gif?cid=ecf05e47p52d27fgwnp1fvyy4czk8c3qmldmekdm1mhs1j8k&rid=giphy.gif&ct=g",
      imageCredit:
        "https://giphy.com/gifs/animatr-anime-aesthetics-anime90s-waves-SWhsTrEYSrGd4CAhNC",
      title: "ocean",
      youtube:
        "https://backend.cryosoft.co.ke/v1/storage/buckets/6239da3c924319cb6478/files/6239db89eb4157c3de95/view?project=6239da2ca5037081177d&mode=admin",
    },
    {
      id: "3",
      image: "https://c.tenor.com/2FIMeyZLsD8AAAAC/anime-1980s.gif",
      imageCredit:
        "https://tenor.com/view/anime-1980s-80s-city-night-gif-16335334",
      title: "traffic",
      youtube:
        "https://backend.cryosoft.co.ke/v1/storage/buckets/6239da3c924319cb6478/files/6239e6f18fc1a85d5dc5/view?project=6239da2ca5037081177d&mode=admin",
    },
    {
      id: "4",
      image: "https://c.tenor.com/L0-NTy43YZIAAAAC/anime-forest.gif",
      imageCredit:
        "https://tenor.com/view/anime-forest-scenery-sunrays-gif-5261536",
      title: "forest",
      youtube:
        "https://backend.cryosoft.co.ke/v1/storage/buckets/6239da3c924319cb6478/files/6239e65d4cce6341c568/view?project=6239da2ca5037081177d&mode=admin",
    },
    {
      id: "5",
      image:
        "https://media2.giphy.com/media/3o7WTQaaMsNunSUImY/giphy.gif?cid=ecf05e47drzybb2zf07xm2wd7kgn0zte2xqh57669hdjj394&rid=giphy.gif&ct=g",
      imageCredit:
        "https://giphy.com/gifs/animated-illustration-starry-night-3o7WTQaaMsNunSUImY",
      title: "silence",
      youtube: "",
    },
    {
      id: "6",
      image: "https://c.tenor.com/9b3VtLqXRAYAAAAC/another-anime.gif",
      imageCredit:
        "https://tenor.com/view/another-anime-kouichi-sakakibara-blink-relaxing-gif-18544197",
      title: "white noise",
      youtube:
        "https://backend.cryosoft.co.ke/v1/storage/buckets/6239da3c924319cb6478/files/6239e949b05c1d24d419/view?project=6239da2ca5037081177d&mode=admin",
    },
    {
      id: "7",
      image: "https://c.tenor.com/HQHhPkADoWoAAAAC/medabots-metabee.gif",
      imageCredit:
        "https://tenor.com/view/medabots-metabee-calor-ventilador-hot-gif-18300475",
      title: "running fan",
      youtube:
        "https://backend.cryosoft.co.ke/v1/storage/buckets/6239da3c924319cb6478/files/6239eb0929c1a956d939/download?project=6239da2ca5037081177d&mode=admin",
    },
    {
      id: "8",
      image:
        "https://media3.giphy.com/media/GQbAyoxyDzWQE/giphy.gif?cid=ecf05e47ojvbl3sf06xjs5a8wfwx9r6d7blquw01jzuvtd73&rid=giphy.gif&ct=g",
      imageCredit: "https://giphy.com/gifs/piano-anime-gif-GQbAyoxyDzWQE",
      title: "river flows in you - yiruma 이루마",
      youtube:
        "https://backend.cryosoft.co.ke/v1/storage/buckets/6239da3c924319cb6478/files/6239ea378d760e43f989/view?project=6239da2ca5037081177d&mode=admin",
    },
    {
      id: "9",
      image:
        "https://media2.giphy.com/media/PQaRWf6SOtCAU/giphy.gif?cid=790b7611d9d84a262a48592ae01bd6b35a1182cd9db077dd&rid=giphy.gif&ct=g",
      imageCredit:
        "https://giphy.com/gifs/maudit-kikis-delivery-service-PQaRWf6SOtCAU",
      title: "[bt21] koya's sleep music",
      youtube:
        "https://backend.cryosoft.co.ke/v1/storage/buckets/6239da3c924319cb6478/files/6239eb7297571eb350e9/view?project=6239da2ca5037081177d&mode=admin",
    },
    {
      id: "10",
      image:
        "https://media4.giphy.com/media/U43yQY2sEqdtgmWkIY/giphy.gif?cid=ecf05e47l1fkswff31pyr6g1sab7gccd441r7ribb5fni9oa&rid=giphy.gif&ct=g",
      imageCredit:
        "https://giphy.com/gifs/fireflies-larapaulussen-lara-paulussen-U43yQY2sEqdtgmWkIY",
      title: "different silence",
      youtube: "",
    },
    {
      id: "11",
      image:
        "https://media1.giphy.com/media/DoIquD1MhDGMw/giphy.gif?cid=ecf05e479yii17y46b92bpnkkgc59ahqbo1caaihcpjfubt9&rid=giphy.gif&ct=g",
      imageCredit: "https://giphy.com/gifs/cat-kitten-DoIquD1MhDGMw",
      title: "cat purring",
      youtube:
        "https://backend.cryosoft.co.ke/v1/storage/buckets/6239da3c924319cb6478/files/6239ed95596d1bcaaa2f/view?project=6239da2ca5037081177d&mode=admin",
    },
    {
      id: "12",
      image: "https://c.tenor.com/JzgronqM0nsAAAAd/dancing-steve.gif",
      title: "minecraft soundtrack",
      imageCredit:
        "https://tenor.com/view/dancing-steve-minecraft-moves-gif-16034488",
      youtube:
        "https://backend.cryosoft.co.ke/v1/storage/buckets/6239da3c924319cb6478/files/6239edacc1a314bb6a36/view?project=6239da2ca5037081177d&mode=admin",
    },
    {
      id: "13",
      image:
        "https://c.tenor.com/04l8AnfK5ckAAAAC/charizard-pokemon-charizard.gif",
      imageCredit:
        "https://tenor.com/view/charizard-pokemon-charizard-flamethrower-angry-mad-gif-23766460",
      title: "dinosaur",
      youtube:
        "https://backend.cryosoft.co.ke/v1/storage/buckets/6239da3c924319cb6478/files/6239eda41206a5607682/view?project=6239da2ca5037081177d&mode=admin",
    },
  ];

  return (
    <MusicInfoContext.Provider value={music}>
      {props.children}
    </MusicInfoContext.Provider>
  );
};
