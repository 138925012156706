import "./App.css";
import MainPage from "./components/MainPage";
import { MusicInfoProvider } from "./Contexts/MusicInfoContext";
import { HashRouter, Routes, Route } from "react-router-dom";
import CardDetails from "./components/CardDetails/CardDetails.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      default: "black",
    },
    primary: {
      main: "#EFB113",
    },
    secondary: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: "Poppins",
    fontWeightLight: 100,
    fontWeightRegular: 200,
    fontWeightMedium: 300,
    fontWeightBold: 400,
  },
  props: {
    MuiIcon: {
      color: "#aa0011",
    },
  },
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          primary: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          border: "none",
          outline: "none",
          borderRadius: "100px",
          textTransform: "lowercase",
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: "#000",
        },
      },
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <MusicInfoProvider>
          <Routes>
            <Route path="/" exact element={<MainPage />} />

            <Route path="/openCard/:id" exact element={<CardDetails />} />
          </Routes>
        </MusicInfoProvider>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
